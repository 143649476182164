import { useEffect, useRef, useState } from "react";
import gsap from "gsap";

import { Link, NavLink } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/logo.svg";

import "../../css/layout/header.scss";

export default function Header({ routes }) {
  const menu = useRef();
  const ctx = useRef();

  useEffect(() => {
    ctx.current = gsap.context(() => {
      gsap.to(menu.current, {
        autoAlpha: 1,
        duration: 2,
        delay: 1,
      });
    }, menu);

    return () => ctx.current.revert();
  }, []);

  const [openMenu, setOpenMenu] = useState(false);
  const menuMobile = useRef();

  useEffect(() => {
    if (openMenu) {
      setTimeout(() => {
        document.getElementsByTagName("body")[0].style.position = "fixed";
      }, 1300);
    } else {
      document.getElementsByTagName("body")[0].style.position = "relative";
    }
  }, [openMenu]);

  const navItems = routes.map(({ path, name }) => (
    <li key={path}>
      <NavLink to={path}>{name}</NavLink>
    </li>
  ));

  const navItemsMobile = routes.map(({ path, name }) => (
    <li
      onClick={() => {
        setOpenMenu(!openMenu);
      }}
      key={path}
    >
      <NavLink to={path}>{name}</NavLink>
    </li>
  ));

  // Start animation

  return (
    <header>
      <nav
        id="menu"
        ref={menu}
      >
        <div
          className="menu-home"
          onClick={() => {
            setOpenMenu(false);
          }}
        >
          <Link to="/">
            <Logo />
          </Link>
        </div>
        <ul className="menu-items">
          {navItems}
          {/*           <li>
            <a href="https://carminanoctis.it/acquista/">Acquista</a>
          </li>
          <li>
            <a href="https://carminanoctis.it/carrello/">
              <svg
                width="100%"
                height="100%"
                version="1.1"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M6,16 C4.9,16 4,16.9 4,18 C4,19.1 4.9,20 6,20 C7.1,20 8,19.1 8,18 C8,16.9 7.1,16 6,16 L6,16 Z M0,0 L0,2 L2,2 L5.6,9.6 L4.2,12 C4.1,12.3 4,12.7 4,13 C4,14.1 4.9,15 6,15 L18,15 L18,13 L6.4,13 C6.3,13 6.2,12.9 6.2,12.8 L6.2,12.7 L7.1,11 L14.5,11 C15.3,11 15.9,10.6 16.2,10 L19.8,3.5 C20,3.3 20,3.2 20,3 C20,2.4 19.6,2 19,2 L4.2,2 L3.3,0 L0,0 L0,0 Z M16,16 C14.9,16 14,16.9 14,18 C14,19.1 14.9,20 16,20 C17.1,20 18,19.1 18,18 C18,16.9 17.1,16 16,16 L16,16 Z" />
              </svg>
            </a>
          </li> */}
        </ul>
        <div
          id="mobile-menu"
          ref={menuMobile}
          className={openMenu ? "open-menu" : "mobile-menu"}
        >
          <div
            id="menuToggle"
            onClick={() => {
              setOpenMenu(!openMenu);
            }}
          >
            <div className="top-line"></div>
            <div className="medium-line"></div>
            <div className="bottom-line"></div>
          </div>
          <div className="mobile-menu-cont">
            <div className="mobile-menu-cont-cont">
              <div className="layer-menu"></div>
              <ul>
                {navItemsMobile}
                <li>
                  <a href="https://carminanoctis.it/acquista/">Acquista</a>
                </li>
                <li>
                  <a href="https://carminanoctis.it/carrello/">Carrello</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}
