import { useEffect, useRef, useState } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { useLocation } from "react-router-dom";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import { mediaQueryAllMobile } from "../helpers/globals";

import "../../css/components/transition.scss";

const TransitionComponent = ({ children }) => {
  gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

  const location = useLocation();
  const firstLoad = useRef(true);

  const [transitionState, setTransitionstate] = useState();

  useEffect(() => {
    if (firstLoad.current) {
      ScrollSmoother.create({
        smooth: 1.5,
        effects: true,
      });
      firstLoad.current = false;
      return;
    }
  }, []);

  useEffect(() => {
    if (!mediaQueryAllMobile) {
      var smoother = ScrollSmoother.create({
        smooth: 2,
        effects: true,
      });
      smoother.scrollTo(0);
    } else {
      window.scrollTo(0, 0);
    }
  }, [transitionState]);

  return (
    <div>
      <TransitionGroup>
        <CSSTransition
          key={location.pathname}
          timeout={600}
          classNames="page"
          unmountOnExit
          onExited={() => {
            setTransitionstate(location.pathname);
          }}
        >
          {children}
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

export default TransitionComponent;
